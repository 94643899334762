import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";

function LastUpdate() {
  const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
    query getLastRecord {
      records(limit: 1, sort: "created_at:desc") {
        created_at
      }
    }
  `);

  useEffect(
    () => {
      loadExpenseStatus({
        variables: {
          date: data,
        },
      });
    }, // eslint-disable-next-line
    []
  );

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-sliver">
        last update
      </dt>
      <dd className="order-1 text-5xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-primary-dark">
        {data !== undefined &&
          new Intl.DateTimeFormat("de", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(data.records[0].created_at))}
      </dd>
    </div>
  );
}

export default LastUpdate;
