import React from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/public/createUploadLink";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Counter from "../components/counter";
import CatCounter from "../components/catCounter";
import CategoriesPreviewCounter from "../components/categoriesPreviewCounter";
import LastUpdate from "../components/lastUpdate";
import TopEight from "../components/topEight";

import SerialGif from "../images/serial-records-GIF.gif";

function IndexPage() {
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: process.env.GATSBY_STAPI,
    }),
    defaultOptions,
  });

  return (
    <ApolloProvider client={client}>
      <Layout>
        <SEO
          keywords={[`series`, `serien`, `schallplatten`, `records`]}
          title="Home"
        />

        <div className="bg-lightGrey lg:relative h-full lg:flex">
          <div className="lg:flex-1 max-w-7xl w-full pt-16 pb-20 center lg:py-15 lg:text-left">
            <div className="px-4 sm:px-8 xl:pr-16">
              <h1 className="text-secondary mt-3 max-w-lg mx-auto text-5xl tracking-tight font-extrabold sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl">
                <span className="block inline">I wonder if you&apos;ll</span>
                <br />
                <span className="block inline">
                  ever look at <span className="text-primary-dark">series</span>
                </span>
                <br />
                <span className="block inline">like that?</span>
              </h1>
              <p className="mt-12 max-w-lg mx-auto text-sliver">
                <b>
                  Diese Webseite ist den Schallplatten gewidmet, welche als
                  „Serie“ erschienen sind.
                </b>{" "}
                Dies ist ein weites – um nicht zu sagen unbegrenztes – Feld. Im
                Rahmen des Möglichen werden Serien vollständig vorgestellt. Da
                dies aus naheliegenden Gründen nicht immer möglich ist, werden
                wir uns meistens auf diejenigen Serien beschränken von denen wir
                mindestens drei Viertel der Platten vorstellen können.
              </p>
              <p className="mt-4 max-w-lg mx-auto text-sliver">
                <b>Ce site est consacré aux vinyls publiés en „série“.</b> Le
                sujet est vaste, pour ne pas dire illimité. Dans la mesure du
                possible les séries sont présentées au complet. Comme cela n’est
                à l’évidence pas toujours possible, nous nous limiterons
                généralement à des séries pour lesquelles nous disposons, à tout
                le moins, de trois quarts des disques concernés.
              </p>
              <p className="mt-4 max-w-lg mx-auto text-sliver">
                <b>
                  This website is dedicated to records of which have been
                  published as „series“.
                </b>{" "}
                This is a broad – not to say unlimited – field. To the extent
                possible, complete series are presented. Since this is not, for
                obvious reasons, always possible, we will most of the time
                restrict ourselves to those series of which we can present at
                least three-quarters of the records.
              </p>
            </div>
          </div>
          <div className="lg:flex-1 w-full bg-lightGrey lg:inset-y-0 lg:right-0 lg:w-1/2 ">
            <img
              className="w-full"
              alt="Animiertes Bild einer Schallplatten Serie"
              src={SerialGif}
            />
          </div>
        </div>

        <div className="z-50">
          <div className="bg-lightGrey pt-12 sm:pt-16 ">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-2xl font-extrabold text-primary-dark sm:text-3xl">
                  Die größte Serienschall&shy;platten&shy;coversammlung der Welt
                </h2>
                <p className="mt-3 text-gray-500 sm:mt-4">
                  Sie wollen zu dieser Sammlung beitragen?
                  <br />
                  Senden Sie eine Nachricht an{" "}
                  <a href="mailto:jpk@serial-records.de">
                    jpk@serial-records.de
                  </a>{" "}
                  .
                </p>
              </div>
            </div>
            <div className="mt-10 pb-12 bg-white sm:pb-16">
              <div className="relative">
                <div className="absolute inset-0 h-1/2 bg-lightGrey" />
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="max-w-6xl mx-auto">
                    <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4">
                      <CatCounter />
                      <Counter />
                      <LastUpdate />
                      <CategoriesPreviewCounter />
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TopEight />
        </div>
      </Layout>
    </ApolloProvider>
  );
}

export default IndexPage;
