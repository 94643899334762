import { gql, useQuery } from "@apollo/client";
import React from "react";
import Link from "gatsby-link";

function TopEight() {
  const { loading, error, data } = useQuery(
    gql`
      query GetCategories($limit: Int) {
        categories(sort: "published_at:desc", limit: $limit) {
          id
          pagetitle
          covers {
            url
          }
        }
      }
    `,
    {
      variables: {
        limit: Number(8),
      },
    }
  );

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div className="grid grid-cols-1 items-center">
      <div className="mt-8 grid grid-cols-2 md:grid-cols-4 lg:mt-0 lg:grid-cols-8">
        {data !== undefined &&
          data.categories.map(
            (category) =>
              category.covers[0] !== undefined && ( // process.env.GATSBY_STAPI_GRAPHQL +
                <Link key={category.id} to={`/category?id=${category.id}`}>
                  <img
                    className="max-h-50"
                    src={category.covers[0].url}
                    alt=""
                  />
                </Link>
              )
          )}
      </div>
    </div>
  );
}

export default TopEight;
