import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";

function CategoriesPreviewCounter() {
  const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
    query categoriesPreviewCount {
      categoriesConnection(where: { published_at_null: true }) {
        aggregate {
          count
        }
      }
    }
  `);

  useEffect(
    () => {
      loadExpenseStatus({
        variables: {
          date: data,
        },
      });
    }, // eslint-disable-next-line
    []
  );

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-sliver">
        series on their way
      </dt>
      <dd className="order-1 text-5xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-primary-dark">
        {data !== undefined && data.categoriesConnection.aggregate.count}
      </dd>
    </div>
  );
}

export default CategoriesPreviewCounter;
